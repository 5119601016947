import React from 'react'
const defaultState ={
    currentBuildDate: "",
    updateBuild: () => {},
}

const StorageContext = React.createContext(defaultState)

class StorageProvider extends React.Component{
    state={
        currentBuildDate:""
    }
    updateBuild = (date) => {
        this.setState({currentBuildDate: date})
    }

    render(){
        const {children} = this.props
        const {currentBuildDate} = this.state
        return(
            <StorageContext.Provider
                value={{
                    currentBuildDate,
                    updateBuild: this.updateBuild
                }}
            >
                {children}
            </StorageContext.Provider>
        )
    }
}

export default StorageContext
export {StorageProvider}